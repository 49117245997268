import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import PageHeading from './../components/page-heading'
import Container from './../components/container'

export default props => {
  const {data} = props
  const prismicData = {
    pageHeading: data.prismicPaypalCancelledPage.data.page_heading.text,
    bodyCopy: data.prismicPaypalCancelledPage.data.body_copy.html,
  }

  return (
    <Layout pageName="paypal-cancel" documentTitlePrefix={prismicData.pageHeading}>
      <PageHeading heading={prismicData.pageHeading} />
      <Container>
        <Fade>
          <StyledPaypalCancelMessage>
            <div dangerouslySetInnerHTML={{__html: prismicData.bodyCopy}} />
          </StyledPaypalCancelMessage>
        </Fade>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prismicPaypalCancelledPage {
      data {
        body_copy {
          html
        }
        page_heading {
          text
        }
      }
    }
  }
`

const StyledPaypalCancelMessage = styled.div`
  font-weight: 100;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16rem;
  max-width: 60rem;
  text-align: center;

  p {
    margin-bottom: 1rem;
  }
`
